<div [formGroup]="price">
  <!-- Product -->
  <h3 *ngIf="first" class="text-truncate" matLine>
    {{ 'product' | translate | ucFirst }} {{ 'pricing' | translate }}
  </h3>
  <h3 *ngIf="!first" class="text-truncate"
      matLine
      matTooltip="{{ productName }}">
    {{ productName | ucFirst }}
  </h3>

  <!-- Enable / Disable Product -->
  <p *ngIf="first" class="first-row row text-truncate header-title">
    {{'enable' | translate | ucFirst}}
  </p>
  <mat-slide-toggle #isEnabled
                    *ngIf="!first"
                    class="first-row row"
                    formControlName="isEnabled">
  </mat-slide-toggle>

  <!-- Dynamic -->
  <div *ngIf="isDynamic()" formGroupName="priceDynamic">
    <!-- Minimum -->
    <p *ngIf="first" class="row text-truncate header-title">
      {{ 'minimum' | translate | ucFirst }} {{ 'price' | translate }}
    </p>
    <p *ngIf="!first" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input #dynamicMinimumPrice (blur)="valOrZero($event.target)"
               autocomplete="off"
               formControlName="dynamicMinimumPrice"
               matInput
               min="0" placeholder="{{'miminum_price' | translate | ucFirst}}"
               step='0.01' type="number">
      </mat-form-field>
    </p>
  </div>

  <!-- Dynamic -->
  <div *ngIf="isHourly()" formGroupName="priceHourly">
    <!-- Minimum -->
    <p *ngIf="first" class="row text-truncate header-title">
      {{ 'minimum' | translate | ucFirst }} {{ 'price' | translate }}
    </p>
    <p *ngIf="!first" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input #hourlyMinimumPrice (blur)="valOrZero($event.target)"
               autocomplete="off"
               formControlName="hourlyMinimumPrice"
               matInput
               min="0" placeholder="{{'miminum_price' | translate | ucFirst}}"
               step='0.01' type="number">
      </mat-form-field>
    </p>
  </div>

  <!-- Wait -->
  <p *ngIf="first" class="row text-truncate header-title">
    {{ 'waiting_price' | translate | ucFirst }}
  </p>
  <p *ngIf="!first" class="row">
    <mat-form-field class="push-right price-input"
                    flex
                    floatLabel="never">
      <span matPrefix>{{currencySymbol}}&thinsp;</span>
      <input #minuteWaitingPrice (blur)="valOrZero($event.target)"
             autocomplete="off"
             formControlName="minuteWaitingPrice"
             matInput
             min="0"
             placeholder="{{ 'waiting_price' | translate | ucFirst }}"
             step='0.01' type="number">
    </mat-form-field>
  </p>

  <!-- Dynamic -->
  <div *ngIf="isDynamic() || isHourly()" formGroupName="{{(isDynamic() ? 'priceDynamic' : 'priceHourly')}}">
    <!-- Start -->
    <p *ngIf="first" class="row text-truncate header-title">
      {{ 'start' | translate | ucFirst }} {{ 'price' | translate }}
    </p>
    <p *ngIf="!first && isDynamic()" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input #dynamicStartPrice (blur)="valOrZero($event.target)"
               autocomplete="off"
               formControlName="dynamicStartPrice"
               matInput
               min="0" placeholder="{{ 'start' | translate | ucFirst }} {{ 'price' | translate }}" step='0.01'
               type="number">
      </mat-form-field>
    </p>

    <p *ngIf="!first && isHourly()" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input #hourlyStartPrice (blur)="valOrZero($event.target)"
               autocomplete="off"
               formControlName="hourlyStartPrice"
               matInput
               min="0" placeholder="{{ 'start' | translate | ucFirst }} {{ 'price' | translate }}" step='0.01'
               type="number">
      </mat-form-field>
    </p>

    <!-- Distance -->
    <p *ngIf="first && isDynamic()" class="row text-truncate header-title">

      {{ 'km'|distanceFormat:company:false | translate | ucFirst }} {{ 'price' | translate }}

      <button (click)="addThreshold('distance')"
              class="row-button button-dynamic"
              mat-icon-button
              matTooltip="Add new distance threshold"
              matTooltipPosition="right">
        <mat-icon *ngIf="origin!=='wizard'" aria-label="Add new threshold icon">add</mat-icon>
      </button>

    </p>
    <p *ngIf="!first && isDynamic()" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input #dynamicDistancePrice (blur)="valOrZero($event.target)"
               autocomplete="off"
               formControlName="dynamicDistancePrice" matInput
               min="0"
               placeholder="{{ 'km'|distanceFormat:company:false | translate | ucFirst }} {{ 'price' | translate }}"
               step='0.01' type="number">
      </mat-form-field>
    </p>

    <!-- Distance Thresholds -->
    <ng-container *ngFor="let threshold of thresholds('dynamic') ; index as i;">
      <div *ngIf="threshold.value.type === 'distance'" [formGroup]="threshold">
        <p *ngIf="first" class="row text-truncate threshold-text">
          - {{ 'after' | translate }}
          <mat-form-field class="push-right threshold-input"
                          flex
                          floatLabel="never">
            <input (change)="updateThreshold(i, $event)" [value]="threshold.value.threshold"
                   autocomplete="off"
                   class="threshold-metric-input"
                   matInput
                   max="999"
                   min="1"
                   step='0.01'
                   type="number">{{'km'|distanceFormat:company:true}}
          </mat-form-field>
          <button (click)="removeThreshold(threshold.value, 'distance')"
                  class="row-button button-dynamic"
                  mat-icon-button
                  matTooltip="{{ 'remove' | translate | ucFirst }} {{ 'threshold' | translate }}"
                  matTooltipPosition="right">
            <mat-icon aria-label="Remove threshold icon" style="color: red;">
              clear
            </mat-icon>
          </button>

        </p>
        <p *ngIf="!first" class="row">
          <mat-form-field class="push-right price-input"
                          flex
                          floatLabel="never">
            <span matPrefix>{{currencySymbol}}&thinsp;</span>
            <input (blur)="valOrZero($event.target)" autocomplete="off"
                   formControlName="value"
                   matInput
                   min="0"
                   placeholder="{{ 'threshold' | translate | ucFirst }}"
                   step='0.01' type="number">
          </mat-form-field>
        </p>
      </div>
    </ng-container>

    <!-- Duration -->
    <p *ngIf="first" class="row text-truncate header-title">
      {{ (isDynamic() ? 'minute' : 'hourly') | translate | ucFirst }} {{ 'price' | translate }}
      <button (click)="addThreshold('duration')"
              class="row-button button-dynamic"
              mat-icon-button
              matTooltip="Add new duration threshold"
              matTooltipPosition="right">
        <mat-icon *ngIf="origin!=='wizard'" aria-label="Add new threshold icon">add</mat-icon>
      </button>
    </p>
    <p *ngIf="!first && isDynamic()" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input #dynamicMinutePrice (blur)="valOrZero($event.target)"
               autocomplete="off"
               formControlName="dynamicMinutePrice" matInput
               min="0"
               placeholder="{{ 'minute' | translate | ucFirst }} {{ 'price' | translate }}"
               step='0.01' type="number">
      </mat-form-field>
    </p>
    <p *ngIf="!first && isHourly()" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input #hourlyPrice (blur)="valOrZero($event.target)"
               autocomplete="off"
               formControlName="hourlyPrice" matInput
               min="0"
               placeholder="{{ 'hour' | translate | ucFirst }} {{ 'price' | translate }}"
               step='0.01' type="number">
      </mat-form-field>
    </p>

    <!-- Duration Thresholds -->
    <ng-container *ngFor="let threshold of thresholds('dynamic') ; index as i;">
      <div *ngIf="threshold.value.type === 'duration'" [formGroup]="threshold">
        <p *ngIf="first" class="row text-truncate threshold-text">
          - {{ 'after' | translate }}
          <mat-form-field class="push-right threshold-input"
                          flex
                          floatLabel="never">
            <input (change)="updateThreshold(i, $event)" [value]="threshold.value.threshold"
                   autocomplete="off"
                   class="threshold-metric-input"
                   matInput
                   max="999"
                   min="1"
                   step='0.01'
                   type="number">min
          </mat-form-field>
          <button (click)="removeThreshold(threshold.value, 'duration')"
                  class="row-button button-dynamic"
                  mat-icon-button
                  matTooltip="{{ 'remove' | translate | ucFirst }} {{ 'threshold' | translate }}"
                  matTooltipPosition="right">
            <mat-icon aria-label="Remove threshold icon" style="color: red;">
              clear
            </mat-icon>
          </button>
        </p>
        <p *ngIf="!first" class="row">
          <mat-form-field class="push-right price-input"
                          flex
                          floatLabel="never">
            <span matPrefix>{{currencySymbol}}&thinsp;</span>
            <input (blur)="valOrZero($event.target)" autocomplete="off"
                   formControlName="value"
                   matInput
                   min="0"
                   placeholder="{{ 'threshold' | translate | ucFirst }}"
                   step='0.01' type="number">
          </mat-form-field>
        </p>
      </div>
    </ng-container>

    <ng-container *ngFor="let threshold of thresholds('hourly') ; index as i;">
      <div *ngIf="threshold.value.type === 'duration'" [formGroup]="threshold">
        <p *ngIf="first" class="row text-truncate threshold-text">
          - {{ 'after' | translate }}
          <mat-form-field class="push-right threshold-input"
                          flex
                          floatLabel="never">
            <input (change)="updateThreshold(i, $event)" [value]="threshold.value.threshold"
                   autocomplete="off"
                   class="threshold-metric-input"
                   matInput
                   max="999"
                   min="1"
                   step='0.01'
                   type="number"> {{ (isDynamic() ? 'min' : 'hour') | translate | ucFirst }}
          </mat-form-field>
          <button (click)="removeThreshold(threshold.value, 'duration')"
                  class="row-button button-hourly"
                  mat-icon-button
                  matTooltip="{{ 'remove' | translate | ucFirst }} {{ 'threshold' | translate }}"
                  matTooltipPosition="right">
            <mat-icon aria-label="Remove threshold icon" style="color: red;">
              clear
            </mat-icon>
          </button>

        </p>
        <p *ngIf="!first" class="row">
          <mat-form-field class="push-right price-input"
                          flex
                          floatLabel="never">
            <span matPrefix>{{currencySymbol}}&thinsp;</span>
            <input (blur)="valOrZero($event.target)" autocomplete="off"
                   formControlName="value"
                   matInput
                   min="0"
                   placeholder="{{ 'threshold' | translate | ucFirst }}"
                   step='0.01' type="number">
          </mat-form-field>
        </p>
      </div>
    </ng-container>
  </div>

  <!-- Fixed -->
  <div *ngIf="isFixed()" formGroupName="priceFixed">
    <!-- Fixed price -->
    <p *ngIf="first" class="row text-truncate header-title">
      {{ 'fixed' | translate | ucFirst }} {{ 'price' | translate }}

      <button (click)="addChildRule(this.parent.parent.rule, true)"
              class="row-button button-dynamic"
              mat-icon-button
              matTooltip="{{ 'add_new_price' | translate }}"
              matTooltipPosition="right">
        <mat-icon aria-label="{{'add_new_price'|translate}}">add</mat-icon>
      </button>
    </p>
    <p *ngIf="!first" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input #fixedPrice (blur)="valOrZero($event.target)"
               autocomplete="off"
               formControlName="fixedPrice" matInput
               min="0" placeholder="{{ 'fixed' | translate | ucFirst }} {{ 'price' | translate }}" step='0.01'
               type="number">
      </mat-form-field>
    </p>
  </div>
</div>

<!-- Fixed Childrules -->
<div *ngIf="isFixed()">
  <div *ngFor="let r of childRules(); let i = index">
    <p *ngIf="first" class="row text-truncate header-title">
      {{ 'fixed' | translate | ucFirst }} {{ 'price' | translate }}
      <button (click)="addChildRule(parent.parent.rule.childRules[i], true)"
              *ngIf="r.value._id && false"
              class="row-button row-button-refresh button-dynamic"
              mat-icon-button
              matTooltip="{{ 'add_retour' | translate | ucFirst }}"
              matTooltipPosition="right">
        <mat-icon aria-label="Remove child rule icon">
          undo
        </mat-icon>
      </button>
      <button (click)="addChildRule(parent.parent.rule.childRules[i], false)"
              *ngIf="r.value._id && false"
              class="row-button row-button-copy button-dynamic"
              mat-icon-button
              matTooltip="{{ 'copy_row' | translate | ucFirst }}" matTooltipPosition="right">
        <mat-icon aria-label="Remove child rule icon">
          file_copy
        </mat-icon>
      </button>
      <button (click)="removeChildRule(r, i)"
              class="row-button button-dynamic"
              mat-icon-button
              matTooltip="{{ 'remove' | translate | ucFirst }} {{ 'price' | translate }}"
              matTooltipPosition="right">
        <mat-icon aria-label="Remove child rule icon" style="color: red;">
          clear
        </mat-icon>
      </button>
    </p>
    <p *ngIf="!first && r.value.prices[colIndex]" class="row">
      <mat-form-field class="push-right price-input"
                      flex
                      floatLabel="never">
        <span matPrefix>{{currencySymbol}}&thinsp;</span>
        <input (blur)="valOrZero($event.target)" (change)="updateChildRulePriceFixed(
            r.value._id,
            r.controls.prices.controls[colIndex].controls.priceFixed,
            $event.target.value
          )"
           [value]="(r.value.prices[colIndex].priceFixed ? r.value.prices[colIndex].priceFixed.fixedPrice : '0.00')"
           autocomplete="off"
           matInput
           min="0" placeholder="{{ 'fixed' | translate | ucFirst }} {{ 'price' | translate }}" step='0.01'
           type="number">
      </mat-form-field>
    </p>
  </div>
</div>
